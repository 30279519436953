.rbc-toolbar {
  display: none !important;
}

.rbc-header + .rbc-header {
  border-left: 0px !important;
}

.rbc-header {
  color: #636363 !important;
  font-size: 16px !important;
  font-weight: 500;
  text-align: right !important;
  line-height: 60px !important;
}

.rbc-month-view {
  border: 0px !important;
}

.rbc-date-cell {
  margin-top: 5px;
  min-height: 2.4rem !important;
}

.rbc-now button {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.rbc-now {
  background-color: inherit;
}

.rbc-today {
  background-color: inherit;
}

.rbc-day-bg.rbc-off-range-bg {
  background-color: inherit;
}
