.general-search {
  border: 0px;
}

.general-search:focus-visible {
  border: 0px;
  outline: unset !important;
}

.general-search-results {
  margin-top: 5px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  top: 37px;
  z-index: 1000;
}

@media (max-width: 992px) {
  .general-search-results {
    width: 94vw;
    left: calc(-34vw);
  }
}

@media (min-width: 992px) {
  .general-search-results {
    width: 100% !important;
    left: unset;
  }
}
