.react-tags__search-input {
  width: 100% !important;
  padding: 0 0.375rem 0 0.5rem !important;
}
.react-pdf__Page__textContent {
  width: 100% !important;
  height: 100% !important;
}

.react-tags {
  position: relative;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  min-height: 2.6rem;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  /* border-color: #b1b1b1; */
  border-color: #0076ba !important;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;

  padding: 6px 8px;
  border: 0px;

  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
  width: 100%;
}

.react-tags__search-wrapper {
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input,
.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  line-height: 2.6rem;
  /* match the font styles */
  font-size: inherit;
}

.react-tags__search input::-ms-clear,
.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  /* max-height: 200px;
    overflow-y: auto; */
  left: 0;
  width: 100%;
  /* z-index: 1050; */
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
    z-index: 1050;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
