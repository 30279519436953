.collapse-question::before {
  font-family: "Font Awesome 5 Free";
  -webkit-transform: none;
  transform: none;
  font-weight: 900;
  content: "\f077";
}

.collapse-question {
  line-height: 15px;
}

.collapse-question.collapsed::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
}

.collapsed-question-content.collapse {
  display: block !important;
  transition: 500ms;
}

.collapsed-question-content.collapse.show,
.collapsed-question-content.collapsing {
  display: none !important;
  transition: 500ms;
}
