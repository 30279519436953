.btn {
  border-radius: 0.563rem;
}

.btn-purple {
  color: #ffffff;
  background-color: #4e2c95;
  border-color: #4e2c95;
}

.btn-purple.disabled {
  color: #eaeaea;
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  color: #ffffff;
  background-color: #49247a;
  border-color: #4e2c95;
}

.btn-purple:active,
.btn-purple.active,
.open .dropdown-toggle.btn-purple {
  background-image: none;
}

.btn-purple.disabled,
.btn-purple[disabled],
fieldset[disabled] .btn-purple,
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled:active,
.btn-purple[disabled]:active,
fieldset[disabled] .btn-purple:active,
.btn-purple.disabled.active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple.active {
  background-color: #4e2c95;
  border-color: #4e2c95;
}

.btn-purple .badge {
  color: #4e2c95;
  background-color: #ffffff;
}

.btn-white {
  color: #5e5e5e;
  background-color: #ffffff;
  border-color: #e3e3e3;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: #5e5e5e;
  background-color: #f9f9f9;
  border-color: #e3e3e3;
}

.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}

.btn-white.disabled,
.btn-white[disabled],
fieldset[disabled] .btn-white,
.btn-white.disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white:hover,
.btn-white.disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled] .btn-white:focus,
.btn-white.disabled:active,
.btn-white[disabled]:active,
fieldset[disabled] .btn-white:active,
.btn-white.disabled.active,
.btn-white[disabled].active,
fieldset[disabled] .btn-white.active {
  background-color: #ffffff;
  border-color: #e3e3e3;
}

.btn-white .badge {
  color: #ffffff;
  background-color: #5e5e5e;
}
html {
  height: 100%;
}

body,
#root {
  min-height: 100%;
  height: 100%;
}

body {
  padding-top: 6.7rem;
  font-family: Lato !important;
  font-size: 0.938rem !important;
  margin-bottom: 0rem;
  font-weight: unset;
  line-height: 1.438 !important;
}
label {
  margin-bottom: 0.8rem;
}

.bg-light-grey {
  background-color: #f6f7f9;
}

.bg-light-grey-alt {
  background-color: #f3f3f3;
}

.color-light-grey {
  color: #f6f7f9;
}

.color-purple {
  color: #4e2c95;
}
.color-grey {
  color: #5e5e5e;
}

.color-black {
  color: #000;
}

.font-size-23 {
  font-size: 23px;
}

.color-strong-grey {
  color: #605353;
}

.top-navbar {
  height: 3.563rem;
}

.top-subnavbar {
  height: 3.125rem;
}

.bg-purple {
  background-color: #4e2c95;
}

.bg-grey {
  background-color: #5e5e5e;
}

.bg-strong-grey {
  background-color: #605353;
}

.page-content-wrapper {
  width: 100%;
}

.page-content-wrapper-meeting {
  width: 100%;
}

.offcanvas {
  bottom: unset !important;
}

.left-menu-meeting {
  background-color: #fff;
  z-index: 10;
}

.filter-grey {
  filter: invert(36%) sepia(5%) saturate(36%) hue-rotate(314deg) brightness(95%)
    contrast(85%);
}

@media (min-width: 992px) {
  .pt-md-2125rem {
    padding-top: 2.125rem;
  }

  .empty-state-height {
    min-height: 450px;
  }

  .fileupload-section {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    /* z-index: 9999; */
    right: 38%;
    margin-bottom: 20px;
    width: 25%;
  }
  .table-hover tbody tr:hover .grid-controls {
    transition: 0.8s;
    display: block;
  }

  .scrollbar-main {
    height: auto !important;
    width: calc(100vw - 24rem) !important;
  }

  .meeting-zindex-1000 {
    z-index: initial;
  }

  .grid-controls {
    display: none;
  }

  .grid-controls li:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }

  .display-gt-md-block {
    display: block;
  }

  .text-md-end {
    text-align: right !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .viewport-padding {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .viewport-meeting-padding {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .display-lt-md-block {
    display: none;
  }

  .display-lt-md-none {
    display: inline-block;
  }

  .offcanvas {
    visibility: visible;
  }

  .left-menu-close-link {
    display: none;
  }

  .page-content-wrapper {
    margin-left: 12.5rem;
  }

  .left-menu-meeting {
    width: 18rem;
  }
  .left-menu-meeting > div {
    width: 17.5rem;
  }

  .moderatorVideoTile {
    margin: 0.5rem;
  }

  .video-control {
    min-width: unset;
    width: 4.5rem;
    margin-right: 0.8rem;
  }

  .fullpage-form {
    width: 40rem !important;
  }

  .text-ellipsis-mobile-38 {
    max-width: 38rem !important;
  }
}

@media (max-width: 992px) {
  .pt-md-2125rem {
    padding-top: 0rem;
  }
  .display-lt-md-none {
    display: none;
  }
  .left-menu {
    left: 0;
  }

  .empty-state-height {
    min-height: 250px;
  }

  .scroll-md-x {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .fileupload-section {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    /* z-index: 9999; */
    right: 12%;
    margin-bottom: 20px;
    width: 80%;
  }

  .text-ellipsis-mobile-18 {
    max-width: 18rem !important;
  }

  .text-ellipsis-mobile {
    max-width: 5rem !important;
  }

  .scrollbar-main {
    height: auto !important;
    width: 100% !important;
  }

  .avatar-fullname {
    width: 9rem !important;
  }

  .avatar-subtitle {
    width: 9rem !important;
  }

  .fullpage-form {
    width: 100%;
  }

  .meeting-zindex-1000 {
    z-index: 1000;
  }

  .moderatorVideoTile {
    margin: 0px;
  }

  .page-content-wrapper-meeting {
    background-color: #fff;
  }
  .video-control {
    min-width: 2.5rem !important;
    margin-right: 0.1rem;
  }

  .left-menu-meeting {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    width: 90%;
    position: fixed;
    height: calc(100% - 8.5rem) !important;
  }
  .left-menu-meeting > div {
    width: 100%;
  }

  .grid-controls {
    display: block;
  }

  .w-md-25 {
    width: 100% !important;
  }

  .w-md-50 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 100% !important;
  }

  .w-md-85 {
    width: 100% !important;
  }

  .text-md-end {
    text-align: unset !important;
  }

  .viewport-padding {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }

  .viewport-meeting-padding {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }

  .display-lt-md-block {
    display: inline-block;
  }

  .display-gt-md-block {
    display: none;
  }
  .page-content-wrapper {
    margin-left: 0rem;
  }

  /* .page-content-wrapper-meeting {
     margin-left: 0rem; 
  } */

  .left-menu-close-link {
    display: block;
  }

  .offcanvas {
    height: 100% !important;
    width: 16rem !important;
    top: 0 !important;
    /* left: 0 !important; */
    padding-left: 2rem;
    padding-top: 2rem;
    transition: none !important;
  }
}

.navbar-nav li a {
  color: white !important;
}

.form-control {
  padding: 0.25rem 1.313rem;
  font-size: 0.938rem;
  line-height: 1.438;
  color: #000000;
  border: 1px solid #d7d7d7;
  border-radius: 0.563rem;
}

.form-control::placeholder {
  color: #aeaeae;
}

.info-text {
  margin-top: 0.313rem;
  font-size: 0.688rem;
  line-height: 1.063rem;
  color: #5e5e5e;
  display: block;
}

.label-top {
  font-size: 0.938rem;
  color: #5e5e5e;
  margin-bottom: 0.688rem;
}

.label-left {
  font-size: 0.938rem;
  color: #5e5e5e;
}
.rounded {
  border-radius: 0.563rem !important;
}

.rounded video {
  border-radius: 0.563rem;
}

.display-1 {
  font-size: 1.875rem !important;
  margin-bottom: 1rem;
  font-weight: bold !important;
  line-height: 2.313rem !important;
}

.display-2 {
  font-size: 1.875rem !important;
  margin-bottom: 0rem;
  font-weight: bold !important;
  line-height: 2.313rem !important;
}

.display-1-no-line-height {
  font-size: 1.875rem !important;
  margin-bottom: 0.313rem;
  font-weight: bold !important;
}

.display-3-no-line-height {
  font-size: 1.563rem !important;
  margin-bottom: 0.313rem;
  font-weight: bold !important;
}

.mt-minus-2125rem {
  margin-top: -2.125rem;
}

.mb-0313rem {
  margin-bottom: 0.313rem !important;
}

.mt-0313rem {
  margin-top: 0.313rem !important;
}

.mb-1438rem {
  margin-bottom: 1.438rem !important;
}

.mt-1438rem {
  margin-top: 1.438rem !important;
}

.pb-2438rem {
  padding-bottom: 2.438rem !important;
}

.pb-1438rem {
  padding-bottom: 1.438rem !important;
}

.display-3 {
  font-size: 1.563rem !important;
  margin-bottom: 0rem;
  font-weight: bold !important;
  line-height: 2.313 !important;
}

.display-3-no-bold {
  font-size: 1.563rem !important;
  margin-bottom: 0rem;
  line-height: 3rem !important;
}

.display-4 {
  font-size: 1.063rem !important;
  margin-bottom: 1.125rem !important;
  font-weight: bold !important;
  line-height: 1.438 !important;
}

.display-4-no-margin {
  font-size: 1.063rem !important;
  font-weight: bold !important;
  line-height: 1.438 !important;
}

.display-4-no-bold {
  font-size: 1.063rem !important;
  margin-bottom: 1.125rem !important;
  line-height: 1.438 !important;
}

.display-5 {
  font-size: 0.875rem !important;
  margin-bottom: 1rem;
  font-weight: bold !important;
  line-height: 1.438 !important;
}

.display-5-no-bold {
  font-size: 0.875rem !important;
  margin-bottom: 1rem;
  line-height: 1.438 !important;
}

.display-5-no-margin {
  font-size: 0.875rem !important;

  font-weight: bold !important;
  line-height: 1.438 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #e3e3e3;
}

.border-left-1 {
  border-left: 1px solid #e3e3e3;
}

.top-subnavbar .nav-link {
  margin-right: 1rem;
  border-radius: 0.563rem;
  color: #605353 !important;
}

.top-subnavbar .nav-link.active {
  background-color: #f6f7f9;
  font-weight: bold;
  color: #4e2c95 !important;
}

.top-subnavbar .nav-link:hover {
  background-color: #f6f7f9;
}

.scroll-x {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.left-menu {
  width: 12rem;
}

.left-menu ul {
  list-style-type: none;
}

.left-menu ul li {
  padding-left: 0px;
  line-height: 2rem;
}

.left-menu ul .nav-link {
  padding-left: 1rem;
}

.left-menu ul ul .nav-link {
  padding-left: 2rem;
}

.left-menu .active {
  border-left: 2px solid #4e2c95;
  margin-left: -1px;
}

.left-menu ul ul li:hover {
  border-left: 2px solid #5e5e5e;
  margin-left: -1px;
}

.left-menu .active a {
  color: #4e2c95;
  font-weight: bold;
}

.pt-2125rem {
  padding-top: 2.125rem;
}

.table th {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2rem;
}

.table td {
  vertical-align: middle !important;
  line-height: 2rem;
}

.table {
  --bs-table-hover-bg: #f6f7f9;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
}

.icon-default {
  vertical-align: middle;
  width: 45px;
  height: 45px;
}

.icon-sm {
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.icon-xs {
  vertical-align: middle;
  width: 25px;
  height: 25px;
}

.avatar-large img {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.avatar-large span span {
  font-size: 1.8rem !important;
  line-height: 60px;
  width: 100%;
  text-align: center;
  color: #fff !important;
}

.avatar-medium img {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.avatar-medium span span {
  font-size: 1.4rem !important;
  line-height: 40px;
  width: 100%;
  text-align: center;
  color: #fff !important;
}

.avatar-xl img {
  vertical-align: middle;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.avatar-default img {
  vertical-align: middle;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.avatar-default span span {
  font-size: 1.8rem !important;
  line-height: 45px;
  width: 100%;
  text-align: center;
}

.avatar-xl span span {
  font-size: 4.7rem !important;
  line-height: 120px;
  width: 100%;
  text-align: center;
  color: #605353 !important;
}

.avatar-sm img {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.avatar-xs img {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.avatar-xs span span {
  font-size: 0.8rem !important;
  line-height: 25px;
  width: 100%;
  text-align: center;
}

.nav-tabs .nav-link {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 1rem;
  white-space: nowrap;
}

.nav-tabs .nav-link .nav-ellipsis,
.nav-tabs .nav-link.nav-ellipsis {
  max-width: 8rem;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.dropdown-item.active,
.dropdown-item:active {
  color: unset !important;
  background-color: inherit !important;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #4e2c95;

  color: #4e2c95;
  background-color: unset;
}

.nav-tabs .nav-link:not(.active):hover {
  color: #000000;
}

.bg-login-img {
  background-image: url("../images/bg.jpg");
  background-size: cover;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 2000px inset;
}

.header-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;
  overflow: hidden;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.text-normal {
  text-decoration: none;
  color: initial;
}

.avatar-wrapper span {
  position: relative;
}

.avatar-wrapper span span {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fff;

  text-transform: uppercase;

  letter-spacing: 1px;
}

.remoteVideoTile {
  width: 12rem;
  height: 8rem;
  position: relative;
}

.remoteVideoTile.empty {
  background-color: #f0f0f0;
}

.remoteVideoTile label {
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin-left: 7px;
  margin-right: 7px;
  background-color: #f8f8f8;
  opacity: 0.7;
  transition: 0.8s;
  padding-top: 5px;
  z-index: 1000;
}

.remoteVideoTile label:hover {
  transition: 0.8s;
  opacity: 1;
}

.remoteVideoTile .avatar-wrapper span span {
  /* font-size: 1.8rem;
  top: -9px;
  left: 11px; */
  color: #605353 !important;
}

.chat-area:focus-visible {
  border: 0px;
  outline: unset;
}

.remoteVideoTile .avatar-wrapper {
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moderatorVideoTile {
  width: 100%;
  height: 100%;
  position: relative;
}

.moderatorVideoTile video {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -2;
}

.moderatorVideoTile .empty {
  background-color: #f0f0f0 !important;
  width: 100%;
  height: 100%;
  z-index: -10;
  position: absolute;
  top: 0;
}

.moderatorVideoTile label {
  position: absolute;
  top: 15px;
  left: 0;
  margin-left: 7px;
  margin-right: 7px;
  background-color: #f8f8f8;
  opacity: 0.7;
  transition: 0.8s;
  padding-top: 5px;
  z-index: 1;
}

.moderatorVideoTile label:hover {
  transition: 0.8s;
  opacity: 1;
}

.moderatorVideoTile .avatar-wrapper {
  position: absolute;
  bottom: 0;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.breakout-avatar .text-ellipsis {
  width: 10rem !important;
}

#contentVideo {
  position: absolute;
  top: 0;
  z-index: 1000;
  /* width: 100%;
  height: 100%; */
  width: calc(100% + 23px);
  height: calc(100% + 8px);
  /* z-index: 1000000000; */
  pointer-events: none;
}

#contentVideo video {
  z-index: 0;
}

.moderators-video-grid {
  height: calc(100% - 12rem);
}

.chat-wrapper {
  height: calc(100% - 8.5rem) !important;
}

.moderatorVideoTile .avatar-large {
  width: 120px;
  height: 120px;
}

.mic-activity {
  width: 24px;
  display: inline-block;
}

.mic-activity svg {
  margin-bottom: 3px;
}

.rounded-9rem {
  border-radius: 9rem;
}

.videoTile-full-screen {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.videoTile-full-screen .moderators-video-grid {
  height: 100% !important;
}

.pointer {
  cursor: pointer;
}

.chat-my-message {
  background-color: #f0f8ff;
  word-break: break-word;
}

.chat-remote-message {
  background-color: #f6f7f9;
  word-break: break-word;
}

.cbIZjj {
  z-index: -1;
}

.video-control {
  background-color: #f8f8f8;
  opacity: 0.7;
  transition: 0.8s;
  padding: 0.8rem;
  border-radius: 2rem;
  display: inline-block;
}

.video-control-panel {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  text-align: center;
}

.list-of-devices {
  white-space: nowrap;
  bottom: 3.5rem;
  width: 13rem;
}

.list-of-devices li {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.list-of-video {
  right: 0;
}

.list-of-audio-input {
  left: 0;
}

a,
a:hover {
  color: unset;
  text-decoration: none;
}

.main-height {
  height: calc(100% - 7rem);
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.moderatorVideoTile div {
  background-color: unset !important;
}

.svg-sm {
  width: 20px !important;
}

.modal-dialog {
  --bs-modal-padding: 1.875rem !important;
  --bs-modal-border-color: rgb(0 0 0 / 0%) !important;
  --bs-modal-border-width: 0 !important;
  --bs-modal-border-radius: 0.563rem !important;
  --bs-modal-header-padding: 1.875rem 1.875rem !important;
}

.modal-footer {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.ql-toolbar,
.ql-container {
  border: 0px !important;
}

.rw-widget-picker {
  min-height: 100% !important;
  background-color: unset !important;
  border-radius: 0.563rem !important;
  /* border: 0px !important; */
}

.rw-widget-input {
  color: unset;
  background-color: unset !important;
  box-shadow: none !important;
}
.rw-input-addon {
  background-color: #fff !important;
  margin: 4px !important;
  border: 0px !important;
}

.time-picker {
  width: 20rem;
}

.question-answer {
  width: calc(100% - 5.5rem) !important;
}

.question-form-answer {
  width: calc(100% - 3.5rem) !important;
}

.a-hover-grey:hover {
  background-color: #f6f7f9;
}

.checkbox:not(:has(div)) {
  border-color: #d7d7d7 !important;
}

.badge {
  font-weight: normal !important;
  font-size: inherit !important;
  --bs-badge-border-radius: 0.563rem !important;
}

.bg-light-purple {
  background-color: #dcceff !important;
}

.bg-light-blue {
  background-color: #cee3ff !important;
}

.bg-lila {
  background-color: #a0fff6 !important;
}

.font-normal {
  font-weight: normal;
}

.conference-video {
  height: calc(100vh - 12rem) !important;
  width: 100% !important;
}

.video-js.vjs-4-3 {
  padding-top: 0px !important;
}

.scrollbar-container {
  height: auto !important;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

html:not([dir="rtl"]) .alert-dismissible {
  padding-right: 3.8125rem;
}

*[dir="rtl"] .alert-dismissible {
  padding-left: 3.8125rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

html:not([dir="rtl"]) .alert-dismissible .close {
  right: 0;
}

*[dir="rtl"] .alert-dismissible .close {
  left: 0;
}

.alert-primary {
  color: #1a107c;
  background-color: #d6d2f8;
  border-color: #c6c0f5;
}

.alert-primary hr {
  border-top-color: #b2aaf2;
}

.alert-primary .alert-link {
  color: #110a4f;
}

.alert-secondary {
  color: #6b6d7a;
  background-color: #f5f6f7;
  border-color: #f1f2f4;
}

.alert-secondary hr {
  border-top-color: #e3e5e9;
}

.alert-secondary .alert-link {
  color: #53555f;
}

.alert-success {
  color: #18603a;
  background-color: #d5f1de;
  border-color: #c4ebd1;
}

.alert-success hr {
  border-top-color: #b1e5c2;
}

.alert-success .alert-link {
  color: #0e3721;
}

.alert-info {
  color: #1b508f;
  background-color: #d6ebff;
  border-color: #c6e2ff;
}

.alert-info hr {
  border-top-color: #add5ff;
}

.alert-info .alert-link {
  color: #133864;
}

.alert-warning {
  color: #815c15;
  background-color: #feefd0;
  border-color: #fde9bd;
}

.alert-warning hr {
  border-top-color: #fce1a4;
}

.alert-warning .alert-link {
  color: #553d0e;
}

.alert-danger {
  color: #772b35;
  background-color: #fadddd;
  border-color: #f8cfcf;
}

.alert-danger hr {
  border-top-color: #f5b9b9;
}

.alert-danger .alert-link {
  color: #521d24;
}

.alert-light {
  color: #7a7b86;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}

.alert-light hr {
  border-top-color: #eaedf1;
}

.alert-light .alert-link {
  color: #62626b;
}

.alert-dark {
  color: #333a4e;
  background-color: #e0e2e6;
  border-color: #d3d7dc;
}

.alert-dark hr {
  border-top-color: #c5cad1;
}

.alert-dark .alert-link {
  color: #1f232f;
}

.close-0-2-24.close {
  display: none;
}
.sortableHelper {
  z-index: 100000;
}

.sortableHandler {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.sortableHandler:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
}

.modal-backdrop {
  z-index: 1055 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Font Awesome 5 Free";
  -webkit-transform: none;
  transform: none;
  font-weight: 900;
  content: "\f054";
}

.strong {
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#pdf-download {
  display: none !important;
}

.ql-editor.ql-blank::before {
  font-style: unset !important;
  opacity: 0.65;
}

.ql-container {
  font-family: unset !important;
  font-size: unset !important;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

.conference-circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border: 5px solid #9a6aff;
  border-radius: 50%;
  right: -10px;
  background-color: #f6f7f9;
}

.grecaptcha-badge {
  display: none;
}
